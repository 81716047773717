AOS.init();

jQuery(document).ready(function ($) {

    jQuery("li.menu-item-has-children a").append("<span class='arrow'><i class='fas fa-chevron-down'></i><i class='fas fa-chevron-up'></i></span>");

    jQuery(".wysiwyg iframe").wrap( "<div class='new' data-aos='fade-down'  data-aos-duration='600'></div>" );
    jQuery(".wysiwyg figure").wrap( "<div class='new' data-aos='fade-down'  data-aos-duration='600'></div>" );

    jQuery('#nav-icon3').click(function () {
        if (jQuery("body").hasClass("openmenu")) {
            jQuery(".header__navigation__list").hide();
            jQuery(".header__navigation__nav").slideUp();
            jQuery(".header__helper").hide();
            setTimeout(function () {
                jQuery(".header__navigation__list").show();
            }, 200);
        } else {
            jQuery(".header__navigation__nav").slideDown();
           if(jQuery("body").hasClass("sticky")){jQuery(".header__helper").delay(400).fadeIn(700);}
        }
        jQuery(this).toggleClass('open');
        jQuery("body").toggleClass('openmenu');

        if (jQuery(".header").height() >= 60) {
            tempheightTop = jQuery(".header").height() + jQuery("#wpadminbar").height();
        } else {
            tempheightTop = "60px";
        }
        jQuery(".header__navigation").css("top", tempheightTop) + jQuery("#wpadminbar").height();
    });

    jQuery(window).scroll(function () {
        if (jQuery(this).scrollTop() > 50) {
            jQuery('.scrolltop:hidden').stop(true, true).fadeIn();
        } else {
            jQuery('.scrolltop').stop(true, true).fadeOut();
        }
    });

    jQuery(".scrolltop").click(function () {
        $("html,body").animate({scrollTop: $(".top").offset().top}, "1000");
        return false
    })

    window.onscroll = function () {
        scrolled();
    };
    var navbar = document.querySelector(".header__content__main");
    var body = document.body;
    var sticky = navbar.offsetTop;
    if (window.pageYOffset >= sticky) {
        body.classList.add("sticky")
    }

    function scrolled() {
        if (window.pageYOffset >= sticky + 65) {
            body.classList.add("sticky")
        } else {
            body.classList.remove("sticky");
        }
    }

    jQuery('.main-slider').slick({
        dots: false,
        infinite: true,
        speed: 1100,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        arrows: true,
        centerMode: false,
        lazyLoad: 'ondemand',
        swipeToSlide: true,
        cssEase: 'ease-out',
        pauseOnHover: true,
        pauseOnFocus: true,
        prevArrow: '<button type="button" class="slick-prev"><i class="fas fa-chevron-left"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="fas fa-chevron-right"></i></button>',
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }, {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
        ]
    });

    jQuery(".youtube,.slick-slide,.btn-play-slider,.btn-pause-slider,.video-box").click(function (e) {
        $('.main-slider').slick('slickPause');
    });
    jQuery(".grid img").click(function (e) {
        window.open(jQuery(this).attr("src"));
    });

    if ((window.location.hash).startsWith("#box")) {
        setTimeout(function () {
            jQuery(window.location.hash).parent().addClass("hovered")
        }, 1100);

    }


    jQuery(".musicbox").mouseenter(function () {
        jQuery(".musicbox").removeClass("hovered");
        jQuery(this).addClass("hovered");
    }).mouseleave(function () {
        jQuery(this).removeClass("hovered");
    })

});


    /* Global site tag (gtag.js) - Google Analytics */
        window.dataLayer = window.dataLayer || [];

        function gtag() {
            dataLayer.push(arguments);
        }

        gtag('js', new Date());
        gtag('config', 'UA-36113559-5');